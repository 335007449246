<template>
  <v-app-bar absolute color="transparent" flat height="85">
    <v-container class="px-0 text-right d-flex align-center">
      <v-toolbar-title class="font-weight-light hidden-xs-only" v-text="title" />

      <v-spacer />

      <v-btn
        v-if="$auth.check()"
        to="/webmaster/dashboard"
        min-height="48"
        min-width="40"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-view-dashboard'"
        />

        <span class="hidden-sm-and-down" v-text="'Dashboard'" />
      </v-btn>

      <v-btn
        to="/"
        min-height="48"
        min-width="40"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-currency-usd'"
        />

        <span class="hidden-sm-and-down" v-text="'Home'" />
      </v-btn>

      <v-btn
        v-if="!$auth.check()"
        to="/register"
        min-height="48"
        min-width="40"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-account-multiple-plus'"
        />

        <span class="hidden-sm-and-down" v-text="'Register'" />
      </v-btn>

      <v-btn
        v-if="!$auth.check()"
        to="/login"
        min-height="48"
        min-width="40"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-fingerprint'"
        />

        <span class="hidden-sm-and-down" v-text="'Login'" />
      </v-btn>

      <v-btn
        v-if="$auth.check()"
        min-height="48"
        min-width="40"
        text
        @click="logout"
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-fingerprint'"
        />

        <span class="hidden-sm-and-down" v-text="'Logout'" />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "PagesCoreAppBar",

  methods: {
    logout() {
      this.$auth.logout()
      this.$router.push({ path: '/' })
    }
  }
};
</script>
